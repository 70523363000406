// Vanilla javascipt for swipe on mobile
document.addEventListener('touchstart', handleTouchStart, false);
document.addEventListener('touchmove', handleTouchMove, false);

var xDown = null;
var yDown = null;

function carouselslide(elemID, direction)
{
    if(direction == 'left'){
        $("#" + elemID).carousel('next');
    }else{
        $("#" + elemID).carousel('prev');
    }
}

function getTouches(evt) {
  return evt.touches ||             // browser API
         evt.originalEvent.touches; // jQuery
}                                                     

function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;                                      
    yDown = firstTouch.clientY;                                      
};                                                

function handleTouchMove(evt) {
    if ( ! xDown || ! yDown ) {
        return;
    }

    var xUp = evt.touches[0].clientX;                                    
    var yUp = evt.touches[0].clientY;

    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;


    let target = evt.target;
    let carousel = target.closest('.carousel');

    if((typeof carousel !== 'undefined') && (carousel !== null)){
        let carouselID = carousel.id;
        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
            if ( xDiff > 0 ) {
                /* left swipe */ 
                carouselslide(carouselID, 'left');
            } else {
                /* right swipe */
                carouselslide(carouselID, 'right');
            }                       
        } else {
            if ( yDiff > 0 ) {
                /* up swipe */ 
                // console.log('up swipe');
            } else { 
                /* down swipe */
                // console.log('down swipe');
            }                                                                 
        }
    }
    /* reset values */
    xDown = null;
    yDown = null;                                             
};